import { axiosBackEnd, axiosImg, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const submitReportOption = async values => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.put(
    `/reportsOption/owner`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  )
}

export const getReportOption = async () => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(`/reportsOption/owner`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export function qrcodeUpload(blob, filename, callback) {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  let formdata = new FormData()
  formdata.append("file", blob, filename)
  axiosImg
    .post("/file/upload", formdata, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      callback(res.data)
    })
    .catch(err => {
      console.error(err)
    })
}
