import React, { useState, useEffect } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { Input, Upload, message, Button, Form } from "antd"

import { toBlob } from "../../utils/helper"
import {
  submitReportOption,
  qrcodeUpload,
  getReportOption,
} from "../../api/reportOption"
import Layout from "../../layouts"

import "@ant-design/compatible/assets/index.css"
// import "antd/es/grid/style/css"
import "./config.css"

const ReportsConfig = () => {
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [qrcodeUrl, setQrcodeUrl] = useState("")
  const [reportUrl, setReportUrl] = useState("")
  const [form] = Form.useForm()

  async function validateDescription(rule, value) {
    if (!value) {
      return Promise.reject("请输入引导描述")
    } else if (value.length > 20) {
      return Promise.reject("引导描述不能超过20个字符")
    }
    return Promise.resolve()
  }

  async function validateName(rule, value) {
    if (!value) {
      return Promise.reject("请输入公众号名称")
    } else if (value.length > 20) {
      return Promise.reject("公众号的名称不能超过20个字符")
    }
    return Promise.resolve()
  }

  function handleQrcodeUpload({ file }) {
    try {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        const urlData = e.target.result
        const base64 = urlData.split(",").pop()
        const fileType = urlData
          .split(";")
          .shift()
          .split(":")
          .pop()

        const blob = toBlob(base64, fileType)
        qrcodeUpload(blob, file.name, data => {
          if (data.data) {
            setQrcodeUrl(data.data)
            message.success("上传成功")
          } else if (data.code === 70006) {
            message.warn("请登录后上传")
          } else {
            message.error(data.message)
          }
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function handleSubmit() {
    const values = await form.validateFields()

    if (qrcodeUrl) {
      setValidated(true)
      const res = await submitReportOption({
        ...values,
        qrcodeUrl,
      })
      if (res && res.data.code === "0000") {
        message.success("配置更新成功，使用报告链接放到公众号内即可")
      }
      setReportUrl(res.data.data.reportUrl)
      setLoading(false)
    } else {
      message.warn("请添加公众号二维码")
    }
  }

  const uploadButton = (
    <div className="upload-box">
      <PlusOutlined style={{ fontSize: 50 }} />
      <span className="upload-text">点击上传公众号二维码</span>
    </div>
  )

  useEffect(() => {
    if (validated) {
      setLoading(true)
    }
  }, [validated])

  useEffect(() => {
    getOptionInfo()
  }, [])

  const getOptionInfo = async () => {
    const res = await getReportOption()
    if (res === undefined || !res.data.code === "0000") {
      return
    }
    const {
      wechatPlatformName,
      qrcodeUrl,
      reportUrl,
      description,
    } = res.data.data
    if (qrcodeUrl) {
      setQrcodeUrl(qrcodeUrl)
    }
    form.setFieldsValue({
      wechatPlatformName,
      qrcodeUrl,
      description,
    })
    setReportUrl(reportUrl)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 19 },
    },
  }

  return (
    <Layout isSpacerDisplay={true} title="GitHub 报告配置">
      <div className="mdnice-report-config-container">
        <div className="mdnice-report-config-inner-container">
          <Form
            {...formItemLayout}
            form={form}
            onFinish={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="upload-container">
              <div className="upload-heading">
                <div className="upload-heading-left">
                  <div className="upload-cover-container">
                    <Upload
                      accept=".jpg,.jpeg,.png"
                      showUploadList={false}
                      customRequest={handleQrcodeUpload}
                      className="upload-cover"
                    >
                      {qrcodeUrl ? (
                        <img
                          src={qrcodeUrl}
                          alt="preview"
                          className="cover-preview"
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                </div>
                <div className="upload-heading-right">
                  <Form.Item
                    label="公众号名称"
                    name="wechatPlatformName"
                    rules={[{ required: true, validator: validateName }]}
                  >
                    <Input placeholder="例如：编程如画（20个文字以内）" />
                  </Form.Item>
                  <Form.Item
                    label="引导描述"
                    name="description"
                    rules={[{ required: true, validator: validateDescription }]}
                  >
                    <Input placeholder="例如：回复【报告】获取你自己的报告（20个文字以内）" />
                  </Form.Item>
                  <Form.Item label="报告链接">
                    <span>{reportUrl}</span>
                  </Form.Item>
                  <Form.Item label="使用说明">
                    <div className="config-intro-pic-container">
                      <img src="https://files.mdnice.com/pic/da093418-f66b-4683-b889-276f3d32f19c.jpg" />
                      <img src="https://files.mdnice.com/pic/42f4406d-c0ea-4792-8294-522562c16ad9.jpeg" />
                    </div>
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button-submit"
                    loading={loading}
                  >
                    更新
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  )
}

export default ReportsConfig
